import * as THREE from 'three';
import EventBus from '../bus';
import { mapMouse, mapClick } from './event';

export function loaderModel(app) {
  // 文字光圈贴图
  app.cylinderTexture = null;
  // 需要旋转的模型集合
  app.rotateYList = [];
  // 射线数组
  app.rayModel = [];
  // 地图上层基础材质，用于mouseover事件
  app.baseShellMaterial = null;
  // 地图上层高亮材质
  app.activeBaseShellMaterial = null;
  // 线group
  app.lineGroup = new THREE.Group();
  app.scene.add(app.lineGroup);
  return new Promise((resolve) => {
    app.loaderModel({
      type: 'gltf',
      url: 'model/guiyangDraco.gltf',
      onProgress: (xhr) => {
        let proportion = parseInt((xhr.loaded / xhr.total) * 100);
        EventBus.$emit('changeLoaidng', proportion);
      },
      onLoad: (object) => {
        console.log(object);
        app.model = object.scene;
        const hiddenObjs = ['fanwei', 'kejikuang', 'guizhoubankuai'];
        const tranObjs = ['fgx', 'bianyuanlight', 'guizhoubankuai01', 'kejixian'];
        app.model.traverse((obj) => {
          if (obj.isMesh) {
            if (tranObjs.includes(obj.name)) {
              obj.material.blending = THREE.AdditiveBlending;
              obj.material.transparent = true;
            }

            if (hiddenObjs.includes(obj.name)) {
              obj.visible = false;
            }

            if (obj.name.indexOf('Cylinder') > -1) {
              if (!app.cylinderTexture) {
                app.cylinderTexture = obj.material.emissiveMap.clone();
              }
              obj.material.transparent = true;
              obj.material.blending = THREE.AdditiveBlending;
              obj.material.side = THREE.DoubleSide;
              obj.material.map = app.cylinderTexture;
              obj.material.emissiveMap = app.cylinderTexture;
            }

            if (obj.name.indexOf('dianwei') > -1) {
              app.rotateYList.push(obj);
            }

            if (obj.name.indexOf('_(1)') > -1) {
              if (!app.baseShellMaterial) {
                app.baseShellMaterial = obj.material.clone();
                app.activeBaseShellMaterial = obj.material.clone();
                app.activeBaseShellMaterial.color = new THREE.Color('#0094fc');
              }
              app.rayModel.push(obj);
            }
          }
        });

        app.scene.add(app.model);

        mapMouse(app);
        mapClick(app);

        setTimeout(() => {
          app.flyTo({
            position: [3.75, 5.2, 7.54],
            controls: [0.07, 1.47, 1.04],
            done: () => {
              setTimeout(() => {
                EventBus.$emit('changeScene', true);
              });
            }
          });
        }, 500);
        resolve();
      }
    });
  });
}
