<template>
  <div class="header animated fadeInDown">
    <h1>智慧一张图</h1>
    <div class="explain">
      <div style="float: left">陕西新莱德电子科技有限公司 版权所有</div>
      <div style="float: right">此大屏数据均为虚假数据，仅限展示使用</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'bigHeader',
    components: {},
    props: {},
    data() {
      return {};
    },
    mounted() {}
  };
</script>
<style lang="less" scoped>
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 120px;
    background: url('./../assets/image/header.png') no-repeat center center;
    background-position-x: -50px;
    z-index: 3;

    h1 {
      color: #bde4ff;
      text-align: center;
      font-size: 64px;
      line-height: 120px;
      letter-spacing: 14px;
    }

    .explain {
      color: #fff;
      font-size: 24px;
      padding: 0 30px;
    }
  }
</style>
