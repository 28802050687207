import * as THREE from 'three';
import { pointTexture } from './texture';

// 点材质
export const pointMaterial = new THREE.PointsMaterial({
  size: 2,
  map: pointTexture,
  fog: true,
  blending: THREE.AdditiveBlending,
  depthTest: false,
  transparent: true, // 透明
  opacity: 1 // 透明度
});
