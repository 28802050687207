import * as THREE from 'three';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';

/**
 * 飞线
 * @param {*} fromPosition 开始坐标
 * @param {*} centerP2 中心点
 * @param {*} toPosition 结束坐标
 * @param {*} color1 线颜色
 * @param {*} color2 动画线颜色
 * @param {*} width 宽度
 * @returns
 */
export function createFlyLine(
  fromPosition,
  centerP2,
  toPosition,
  color1 = '#fff',
  color2 = '#ff0000',
  width = 0.005
) {
  let CTpoint = [];

  if (centerP2) {
    if (Array.isArray(centerP2) == true) {
      CTpoint = centerP2;
    } else {
      CTpoint[1] = centerP2;
      CTpoint[0] = (fromPosition[0] + toPosition[0]) / 2;
      CTpoint[2] = (fromPosition[2] + toPosition[2]) / 2;
    }
  } else {
    CTpoint = [0, 10, 0];
  }

  const spline = new THREE.QuadraticBezierCurve3(
    new THREE.Vector3(fromPosition[0], fromPosition[1], fromPosition[2]),
    new THREE.Vector3(CTpoint[0], CTpoint[1], CTpoint[2]),
    new THREE.Vector3(toPosition[0], toPosition[1], toPosition[2])
  );
  let point = new THREE.Vector3();
  let positions = [];
  let colors = [];

  for (let i = 0, l = 100; i < l; i++) {
    const t = i / l;
    spline.getPoint(t, point);
    positions.push(point.x, point.y, point.z);

    let color = new THREE.Color(color1);
    colors.push(color.r, color.g, color.b);
  }

  const geometry = new LineGeometry();
  geometry.setPositions(positions);
  geometry.setColors(colors);

  let lineMat = new LineMaterial({
    color: '#fff',
    linewidth: width,
    vertexColors: true,
    dashed: false,
    alphaToCoverage: true
  });

  let line = new Line2(geometry, lineMat);
  line.computeLineDistances();

  function Style() {
    let delta;
    let now = 0;
    let ss1 = 0;
    let changeColor = new THREE.Color(color2);
    let yuanColor = new THREE.Color(color1);

    function updates() {
      requestAnimationFrame(updates);

      delta = new Date().getTime();
      if (delta - now > 30) {
        if (ss1 < 100) {
          colors[ss1 * 3 - 1] = changeColor.b;
          colors[ss1 * 3 - 2] = changeColor.g;
          colors[ss1 * 3 - 3] = changeColor.r;
        }
        ss1++;
        if (ss1 > 100) {
          ss1 = 0;
          let colorYuan = [];
          for (let i = 0; i < 100; i++) {
            colorYuan.push(yuanColor.r, yuanColor.g, yuanColor.b);
          }
          colors = colorYuan;
        }
        geometry.setColors(colors);
        now = delta;
      }
    }
    updates();
  }
  Style();

  return line;
}
