<template>
  <div class="main">
    <loader :number="loadingNumber" v-if="loadingNumber !== 100"></loader>
    <video id="video" class="video" autoplay loop muted>
      <source src="video/kjk.mp4" />
    </video>
    <div class="mask"></div>
    <div v-if="loadingNumber === 100 && isShowChart">
      <big-header></big-header>
      <big-left class="left"></big-left>
      <big-right class="right"></big-right>
      <big-top class="top"></big-top>
      <tooltip
        :style="{
          visibility: tooltipStyle.show ? 'visible' : 'hidden',
          left: tooltipStyle.x + 'px',
          top: tooltipStyle.y + 'px'
        }"
        :name="tooltipStyle.name"
      ></tooltip>
    </div>
    <div id="screen" class="screen"></div>
  </div>
</template>

<script>
  import loader from '@/components/loader';
  import ZThree from '@/three/ZThree';
  import * as THREE from 'three';
  import { loaderModel } from '@/three/loaderModel';
  import { CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer';
  import { cssRender } from '@/three/cssRender';
  import bigHeader from '@/components/header';
  import bigLeft from '@/components/left';
  import bigRight from '@/components/right';
  import bigTop from '@/components/top';
  import tooltip from '@/components/tooltip';
  import { addPoints } from '@/three/points';
  import { TWEEN } from 'three/examples/jsm/libs/tween.module.min.js';

  let app, camera, scene, renderer, controls, clock, pointScene;

  export default {
    name: 'Home',
    components: {
      loader,
      bigHeader,
      bigLeft,
      bigRight,
      bigTop,
      tooltip
    },
    data() {
      return {
        // loading数值
        loadingNumber: 0,
        isShowChart: false,
        tooltipStyle: {
          show: false,
          x: 0,
          y: 0,
          name: 'xxx'
        }
      };
    },
    methods: {
      async initZThree() {
        app = new ZThree('screen');
        app.initThree();
        // app.initHelper();
        app.initLight();
        app.initOrbitControls();

        window.app = app;
        camera = app.camera;
        camera.position.set(13.53, 20.96, 11.03);
        scene = app.scene;
        renderer = app.renderer;
        renderer.outputEncoding = THREE.sRGBEncoding; //采用sRGBEncoding
        controls = app.controls;
        controls.target.set(0.41, 0.77, 1.37);
        controls.maxPolarAngle = Math.PI / 2.2;

        clock = new THREE.Clock();

        await loaderModel(app);

        let instance = new cssRender(CSS3DRenderer, app);
        app.cssRenderer = instance.cssRenderer;
        app.instance = instance;

        //粒子特效
        const total = 50;
        const height = 40;
        const width = 40;
        const depth = 40;
        pointScene = addPoints(app, total, height, width, depth);

        app.render(() => {
          if (this.isShowChart) {
            if (pointScene) {
              pointScene.geometry.vertices.forEach((item) => {
                item.x -= item.direction.x * 0.1;
                item.y -= item.direction.y * 0.1;
                item.z -= item.direction.z * 0.1;
                if (item.x > width || item.x < -width) item.x = item.origin.x;
                if (item.y > width || item.y < -width) item.y = item.origin.y;
                if (item.z > width || item.z < -width) item.z = item.origin.z;
              });
              pointScene.geometry.verticesNeedUpdate = true;
            }
          }

          if (app.cylinderTexture) {
            app.cylinderTexture.offset.y -= 0.0085;
          }

          if (app?.rotateYList?.length) {
            app.rotateYList.forEach((e) => e.rotateY(-0.02));
          }

          controls.update(clock.getDelta());
          renderer.render(scene, camera);
          app.cssRenderer.render(scene, camera);
          TWEEN.update();
        });
      }
    },
    mounted() {
      this.$EventBus.$on('changeLoaidng', (val) => {
        this.loadingNumber = val;
      });
      this.$EventBus.$on('changeScene', (val) => {
        this.isShowChart = val;
      });
      this.$EventBus.$on('changeTooltip', (val) => {
        this.tooltipStyle = val;
      });
      this.initZThree();
    }
  };
</script>

<style lang="less" scoped>
  .main {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image: url('./../assets/image/BG@2x.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .video {
      position: absolute;
      width: 0;
      height: 0;
    }

    .mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('./../assets/image/mask.png');
      background-size: 100% 100%;
      pointer-events: none;
      z-index: 3;
    }

    .screen {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
    }

    .left {
      width: 1200px;
      height: 82%;
      position: fixed;
      top: 160px;
      left: 24px;
      z-index: 3;
    }
    .right {
      width: 1200px;
      height: 82%;
      position: fixed;
      top: 160px;
      right: 24px;
      z-index: 3;
    }
    .top {
      width: 984px;
      height: 16%;
      position: fixed;
      top: 152px;
      right: 1466px;
      z-index: 3;
      float: left;
    }

    .back {
      width: 48px;
      height: 16%;
      position: fixed;
      bottom: -80px;
      right: 1909px;
      z-index: 3;
      cursor: pointer;
      img {
        width: 100%;
      }
      p {
        color: #fff;
        text-align: center;
      }
    }
  }
</style>
<style>
  .text-3d {
    padding: 0 10px;
    background: url('./../assets/image/bed_bg.png') no-repeat;
    background-size: 100% 100%;
    color: #fff;
    font-size: 24px;
    line-height: 48px;
    text-align: center;
    cursor: pointer;
  }
</style>
