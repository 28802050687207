import EventBus from '../bus';
import * as THREE from 'three';
import { lineData } from '@/assets/data';
import { createFlyLine } from '@/three/flyLine';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min.js';
// 高亮模型
let mouseActiveModel = null;

export function mapMouse(app) {
  app.initRaycaster(
    (activeObj, evt) => {
      /**
       * 逻辑：在射线检测到模型后，先判断之前高亮模型是否存在，如果存在，即恢复原材质，不存在及赋予新的高亮材质
       */
      if (activeObj) {
        if (mouseActiveModel) {
          mouseActiveModel.material = app.baseShellMaterial;
        }
        mouseActiveModel = activeObj.object;
        mouseActiveModel.material = app.activeBaseShellMaterial;
        EventBus.$emit('changeTooltip', {
          show: true,
          x: evt.clientX,
          y: evt.clientY,
          name: mouseActiveModel.name
        });
      } else {
        if (mouseActiveModel) {
          mouseActiveModel.material = app.baseShellMaterial;
          mouseActiveModel = null;
          EventBus.$emit('changeTooltip', {
            show: false,
            x: evt.clientX,
            y: evt.clientY
          });
        }
      }
    },
    app.rayModel,
    'mousemove'
  );
}

// 储存移动过的模型
let moveModelNames = [];
export function mapClick(app) {
  app.initRaycaster(
    (activeObj) => {
      if (app.controls.isClickLock) {
        app.controls.isClickLock = false;
        return;
      }
      /**
       * 逻辑：
       * 1. 首先判断是否点击获取到模型，判断模型的名称和在数据中是否有数据存在
       * 2. 判断在数组中是否有储存的点击模型，如果存在，把模型数组中的类型全部置为向下移动和显示线条的状态
       * 3. 判断在数组中是否存在当前点击到的模型，如果存在，把当前模型的运动状态改为空，不需要进行移动，也不需要显示线条，如果不存在则在数组中增加
       * 4. 判断data数据中获取到需要移动的模型和数组中的模型是否存在，不存在则添加模型向上移动状态，存在即置为不需要移动
       * 5. 在点击不是射线检测模型数组时将数组重置并删除所有线条
       */
      if (activeObj && lineData[activeObj.object.name]) {
        const name = activeObj.object.name;
        const textName = name.substr(0, name.indexOf('_')) + '_zi';
        const textModel = app.model.getObjectByName(textName);
        const toPosition = getWorldPosition(textModel);
        const parentModel = textModel.parent;

        if (moveModelNames.length > 0) {
          for (let i = app.lineGroup.children.length - 1; i >= 0; i--) {
            const e = app.lineGroup.children[i];
            app.lineGroup.remove(e);
          }
          moveModelNames.forEach((item) => {
            item.type = 'down';
            item.isLine = true;
          });
        }

        if (
          moveModelNames.length > 0 &&
          moveModelNames.map((item) => item.model.name).includes(parentModel.name)
        ) {
          moveModelNames.forEach((item) => {
            if (item.model.name === parentModel.name) {
              item.type = 'none';
              item.isLine = false;
            }
          });
        } else {
          moveModelNames.push({
            type: 'up',
            model: parentModel,
            isLine: false
          });
        }

        lineData[activeObj.object.name].map((item) => {
          const fromModel = app.model.getObjectByName(item.name);
          if (
            moveModelNames.length > 0 &&
            moveModelNames.map((item) => item.model.name).includes(fromModel.parent.name)
          ) {
            moveModelNames.forEach((item) => {
              if (item.model.name === fromModel.parent.name) {
                item.type = 'none';
              }
            });
          } else {
            moveModelNames.push({
              type: 'up',
              model: fromModel.parent,
              isLine: true
            });
          }
        });

        for (let i = moveModelNames.length - 1; i > -1; i--) {
          const item = moveModelNames[i];
          const fromPosition = getWorldPosition(item.model);
          if (item.type === 'up') {
            mapShellMove(app, item.model, 0.3, () => {
              if (item.isLine) {
                addFlyLine(fromPosition, toPosition);
              }
            });
          } else if (item.type === 'down') {
            mapShellMove(app, item.model, -0.3);
            moveModelNames.splice(i, 1);
          } else if (item.type === 'none' && item.isLine) {
            addFlyLine(fromPosition, toPosition);
          }
        }
      } else {
        if (moveModelNames.length > 0) {
          moveModelNames.forEach((item) => {
            mapShellMove(app, item.model, -0.3);
          });
          moveModelNames = [];
          for (let i = app.lineGroup.children.length - 1; i >= 0; i--) {
            const e = app.lineGroup.children[i];
            app.lineGroup.remove(e);
          }
        }
      }
    },
    app.rayModel,
    'click'
  );
}

/**
 * 增加飞线
 * @param {*} fromPosition
 * @param {*} toPosition
 */
function addFlyLine(fromPosition, toPosition) {
  const line = createFlyLine(
    Object.values(fromPosition),
    5,
    Object.values(toPosition),
    'rgb(94,168,242)',
    'rgb(0,144,255)',
    0.002
  );
  app.lineGroup.add(line);
}

/**
 * 获取世界坐标
 * @param {*} obj
 * @returns
 */
function getWorldPosition(obj) {
  const position = new THREE.Vector3();
  obj.getWorldPosition(position);
  return position;
}

/**
 * 模型移动
 * @param {*} app
 * @param {*} obj
 * @param {*} y
 * @param {*} callback
 */
function mapShellMove(app, obj, y, callback) {
  const fromPosition = getWorldPosition(obj);
  const tween = new TWEEN.Tween({
    y: fromPosition.y
  });
  tween.to(
    {
      y: fromPosition.y + y
    },
    300
  );

  tween.easing(TWEEN.Easing.Sinusoidal.InOut);
  tween.onUpdate(function() {
    app.controls.enabled = false;
    obj.position.y = tween._object.y;
  });
  tween.onComplete(() => {
    app.controls.enabled = true;
    if (callback) {
      callback();
    }
  });
  tween.start();
}
