export const lineData = {
  'xifengxian_(1)': [
    {
      name: 'huaxiqu_zi'
    },
    {
      name: 'qingzhenshi_zi'
    },
    {
      name: 'kaiyangxian_zi'
    }
  ],
  'kaiyangxian_(1)': [
    {
      name: 'baiyunqu_zi'
    },
    {
      name: 'qingzhenshi_zi'
    },
    {
      name: 'nanmingqu_zi'
    }
  ],
  'huaxiqu_(1)': [
    {
      name: 'qingzhenshi_zi'
    },
    {
      name: 'yunyanqu_zi'
    },
    {
      name: 'baiyunqu_zi'
    },
    {
      name: 'xifengxian_zi'
    },
    {
      name: 'kaiyangxian_zi'
    }
  ],
  'baiyunqu_(1)': [
    {
      name: 'wudangqu_zi'
    },
    {
      name: 'nanmingqu_zi'
    },
    {
      name: 'huaxiqu_zi'
    },
    {
      name: 'xifengxian_zi'
    }
  ]
};
